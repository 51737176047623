import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { User } from '../models/user-model';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private router: Router = inject(Router);

  private user = signal<undefined | User>(environment.defoultUser);
  isLogged = computed(() => {
    return !!this.user();
  });
  userName = computed(() => {
    return this.user()?.name || 'Not logged in';
  });
  userMail = computed(() => {
    return this.user()?.email || '';
  });

  token = computed(() => {
    return this.user()?.token;
  });
  constructor() {
    effect(() => {
      if (!this.isLogged()) {
        this.router.navigateByUrl('login');
        return;
      }
      this.router.navigateByUrl('');
    });
  }

  public Login(data: User | undefined) {
    this.user.set(data);
  }

  public Logout() {
    this.user.set(undefined);
  }
}
